<template>
  <div>
    <ejs-dialog
      ref="linkMemberPopupDialog"
      header="연계회원 회원번호 등록"
      :allowDragging="true"
      :showCloseIcon="true"
      width="337"
      :close="dialogClose"
      :position="{ x: 'center' }"
      :enableResize="false"
      :isModal="true"
    >
      <div class="window registeredMembershipMembershipNumber">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="linkMemberGrid"
                        :provides="provide"
                        :columns="columns"
                        :isNOColumnDisplay="false"
                        :dataSource="linkMemberList"
                        :isInPopup="true"
                        :selectionSettings="linkMemberGridSelectionSettings"
                        @dialogGridDoubleClickedOrEnterKeyed="onConfirmClicked"
                        @queryCellInfo="onLinkMemberGridQueryCellInfo"
                        @recordClick="onLinkMemberGridRecordClick"
                      >
                      </ejs-grid-wrapper>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                button-div="SAVE"
                :is-shortcut-button="true"
                v-on:click.native="onConfirmClicked"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" v-on:click.native="onCloseClicked"> 닫기 </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <member-select-popup
      v-if="isMemberSelectPopupOpen"
      ref="memberSelectPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupClosed="memberSelectPopupClosed"
      @popupConfirmed="memberSelectPopupConfirmed"
    />
  </div>
</template>

<script>
import { getMemberLinkListForRegist } from "@/api/member";

import { ForeignKey } from "@syncfusion/ej2-vue-grids";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton.vue";
import MemberSelectPopup from "@/views/common/MemberSelectPopup";

export default {
  // TODO : dialog 용 mixin 하나 제작해서 공통 로직 거기로 넣기
  name: "linkMemberPopup",
  props: [],
  mixins: [confirmDialogMixin],
  components: {
    ejsGridWrapper,
    ErpButton,
    MemberSelectPopup,
  },
  data: function () {
    return {
      columns: [
        {
          field: "memberNo",
          allowEditing: "false",
          headerText: "회원번호",
          isPrimaryKey: true,
        },
        { field: "memberName", allowEditing: "false", headerText: "회원명" },
      ],
      provide: [ForeignKey],
      linkMemberList: [],
      linkMemberGridEditSettings: {
        allowEditing: false,
        allowAdding: false,
        allowDeleting: false,
        mode: "Batch",
        showConfirmDialog: false,
      },
      linkMemberGridSelectionSettings: { enableToggle: false },
      memberNo: "",
      linkmemberId: "",
      isMemberSelectPopupOpen: false,
    };
  },
  methods: {
    showPopup: function (memberNo, linkmemberId) {
      this.memberNo = memberNo;
      this.linkmemberId = linkmemberId;
      this.setLinkMemberList();
      this.$refs.linkMemberPopupDialog.show();
    },
    setLinkMemberList() {
      getMemberLinkListForRegist(this.memberNo, this.linkmemberId)
        .then((response) => {
          this.linkMemberList = response.value.memberList;
        })
        .catch((error) => {
          console.log("getMembershipDetail.err.===>", error);
        });
    },
    dialogClose() {
      this.$emit("popupClosed", null);
    },
    onConfirmClicked() {
      if (
        this.$refs.linkMemberGrid.getSelectedRecords().length > 0 &&
        !this.$refs.linkMemberGrid.getSelectedRecords()[0].memberId
      ) {
        let selectedMember = this.$refs.linkMemberGrid.getSelectedRecords()[0];
        if (Array.isArray(selectedMember.membershipList)) {
          selectedMember.membershipList.forEach((member) => {
            if (!Array.isArray(member.memberlinkList)) {
              member.memberlinkList = [];
            }
          });
        }
        const addMemberId = this.linkMemberList.find(item => item.memberNo === selectedMember.memberNo)?.addMemberId || null;
        selectedMember.addMemberId = addMemberId;

        this.$emit("popupConfirmed", selectedMember);
        this.$refs.linkMemberPopupDialog.hide();
      } else {
        this.errorToast("이미 사용중인 회원번호입니다");
        this.$emit("popupClosed", null);
      }
    },
    onCloseClicked() {
      this.$refs.linkMemberPopupDialog.hide();
    },
    onLinkMemberGridQueryCellInfo(args) {
      const {
        column: {
          field,
        },
        cell,
        data,
      } = args;

      if (field === "memberName" && !data.memberId) {
        // cell.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    onLinkMemberGridRecordClick(args) {
      const {
        rowData,
        column: {
          field,
        },
      } = args;

      if (field === "memberName" && !rowData?.memberId) {
        // this.onMemberPopupOpen(rowData);
      }
    },
    onMemberPopupOpen(data) {
      this.isMemberSelectPopupOpen = true;

      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup({
          parentData: data,
          nomDataViewFlag: false,
        });
      });
    },
    memberSelectPopupClosed() {
      this.isMemberSelectPopupOpen = false;
    },
    async memberSelectPopupConfirmed(args) {
      const {
        popupData: {
          parentData: {
            memberNo,
          },
        },
        selectedRowData,
      } = args;

      this.isMemberSelectPopupOpen = false;

      if (this.linkmemberId === selectedRowData?.membershipId) {
        this.errorToast("대표 회원은 선택하실 수 없습니다");
        return;
      }

      const index = this.linkMemberList.findIndex(item => item.memberNo === memberNo);

      if (!(index >= 0)) {
        this.errorToast("연계 회원번호를 찾을 수 없습니다");
        return;
      }

      this.linkMemberList[index].memberName = "사용(" + selectedRowData.memberName + ")";
      this.linkMemberList[index].addMemberId = selectedRowData.memberId;

      await this.$nextTick(() => {
        const rowIndex = this.$refs.linkMemberGrid.getRowIndexByPrimaryKey(memberNo);
        this.$refs.linkMemberGrid.selectRow(rowIndex);
      });
    },
  },
};
</script>
