<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field memberNameNumber">
            <div class="title">
              {{ labels.MemberName }}
            </div>
            <ul class="content">
              <li class="item">
                <input-text
                  ref="searchMemberName"
                  v-model="q"
                  @keydown.enter="onSearchClick"
                />
              </li>
            </ul>
          </li>
          <li class="field memberNameNumber">
            <div class="title">
              {{ labels.endOfHpNumber }}
            </div>
            <ul class="content">
              <li class="item">
                <input-text
                  v-model="searchMembersFilterOptions.endOfHpNumber"
                  placeholder="핸드폰 뒤 4자리"
                  maxlength="4"
                  @keydown.enter="onSearchClick"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              v-on:click.native="onSearchClick"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
              button-div="GET"
              :is-icon-custom="true"
              @click.native="openSearchMembersFilterOptionsPopup"
          >
            상세검색
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true" />
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">회원 목록</div>
              <div class="header-caption">
                [ {{ totalMemberships | numberWithCommas }} 건 ]
              </div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="add">
                  <erp-button
                      button-div="GET"
                      :ignore="isPopupOpened"
                      :is-icon-custom="true"
                      :is-custom-shortcut-button="true"
                      shortcut-key="memberInfoRegistration.shortcuts.add"
                      :shortcut="{key: 'F3'}"
                      @click.native="onMemberAddClicked"
                  >
                    추가
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      @click.native="print"
                      :is-custom-shortcut-button="true"
                      shortcut-key="memberInfoRegistration.shortcuts.print"
                      :shortcut="{altKey:true, key: 'P'}"
                  >
                    인쇄
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onExcelClick"
                  >
                    Excel
                  </erp-button>
                </li>
                <li class="refresh">
                  <erp-button
                      button-div="GET"
                      :is-icon-custom="true"
                      @click.native="onClickRefresh">
                    새로고침
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <grid
              ref="membershipGrid"
              v-bind="membershipGridProps"
              @cellClick="onMemberGridCellClick"
              @filtered="onMemberGridDataFiltered"
              @sorted="onMemberGridDataFiltered"
              @paged="onMemberGridDataFiltered"
              @cellEnter="onMemberGridCellClick"
            />
          </div>
        </section>
      </article>
    </div>
    <search-members-filter-options-popup
      v-if="visibleSearchMembersFilterOptionsPopup"
      ref="search-members-filter-options-popup"
      :q="q"
      :filterOptions="searchMembersFilterOptions"
      :labels="labels"
      @submit="onSearchMembersFilterOptionsPopupSubmit"
      @close="onSearchMembersFilterOptionsPopupClose"
    />
    <member-info-popup
      v-if="visibleMemberInfoPopupOpen"
      ref="memberInputPopup"
      :member-id="memberInfoMemberId"
      :member-no="memberInfoMemberNo"
      :is-out="searchMembersFilterOptions.isOut"
      @close="onCloseMemberInfoPopup"
    />
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.memberNameNumber .content .item {width: 120px;}
body .appContent .body-article .section-body {overflow: hidden;border: none;}
</style>

<script>
import moment from "moment";
import {mapActions, mapGetters, mapMutations} from "vuex";
import commonMixin from "@/views/layout/mixin/commonMixin";
import { commonCodesGetComName } from "@/utils/commonCodes";
import { numberWithCommas } from "@/utils/number";
import MemberInfoPopup from "./MemberInfoPopup";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ReportView from "@/components/common/report/ReportView";
import SearchMembersFilterOptionsPopup from "@/views/member-management/popup/SearchMembersFilterOptionsPopup";
import Grid from "@/components/grid/Grid";
import ErpButton from "@/components/button/ErpButton.vue";
import {
  FORMAT_MEMBER_NUMBER,
  FORMAT_NUMBER,
  FORMAT_TEL_CELLPHONE_NUMBER,
} from "@/components/grid/GridCellDataFormatUtil";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import GolfERPService from '@/service/GolfERPService';
import {DATE_FORMAT_YYYY_MM_DD} from '@/utils/date';

export default {
  name: "memberInfoRegistration",
  components: {
    ErpButton,
    Grid,
    SearchMembersFilterOptionsPopup,
    InputText,
    MemberInfoPopup,
    ReportView,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  filters: {
    numberWithCommas,
  },
  async created() {
    await this.initialize();
  },
  async mounted() {
    // 기본 포커싱
    this.$refs.searchMemberName.focusIn();

    const queryQ = this.$route.query.memberNo;

    if (queryQ) {
      this.q = queryQ;

      await this.onSearchClick();
    }
  },
  data() {
    return {
      now: null,

      isLoading: false,
      isSearched: false,

      memberInfoMemberId: null,
      memberInfoMemberNo: null,

      visibleSearchMembersFilterOptionsPopup: false,
      visibleMemberInfoPopupOpen: false,
      visiblePrintPaper: false,

      q: "",
      searchMembersFilterOptions: {
        corporationDivision: "",
        memberDivision: "",
        memberGrade: "",
        corporationName: "",
        endOfHpNumber: "",
        webMemberId: "",
        groupCompanyCode: "",
        affltsCompanyCode: "",
        smsReceiptDivision: "",
        membershipStatus: "",
        acquireDateFrom: "",
        acquireDateTo: "",
        expireDateFrom: "",
        expireDateTo: "",
        nearbyCode: "",
        birthday: "",
        gender: "",
        isOut: false,
      },

      labels: {
        MemberName: "회원명/회원번호/법인명",
        MemberNamePopup: "회원명/번호",
        corporationDivision: "개인/법인",
        memberDivision: "회원구분",
        memberGrade: "회원등급",
        corporationName: "법인명",
        endOfHpNumber: "핸드폰",
        webMemberId: "웹 ID",
        groupCompanyCode: "그룹사",
        affltsCompanyCode: "계열사",
        smsReceiptDivision: "SMS 수신",
        membershipStatus: "회원권 상태",
        acquireDate: "취득일자",
        nearbyCode: "지역민구분",
        expireDate: "만기일",
        birthday: "생년월일",
        gender: "성별",
        isOut: "탈회여부",
      },

      totalMemberships: 0,
      memberships: [],
    };
  },
  computed: {
    ...mapGetters(["username"]),
    ...mapGetters("documents", ["isMemberRegistrationExcelDownloading"]),
    isPopupOpened() {
      return (
        this.visibleSearchMembersFilterOptionsPopup ||
        this.visibleMemberInfoPopupOpen
      );
    },
    datetimeString() {
      return moment().format(`YYYY-MM-DD HH:mm:ss`);
    },
    membershipGridProps() {
      return {
        columns: [
          {
            minWidth: 16,
            name: "회원번호",
            width: 100,
            field: "memberNumber",
            type: String,
            format: FORMAT_MEMBER_NUMBER,
            class: () => [this.$t("className.grid.clickArea")],
            cellClass: () => [this.$t("className.grid.clickArea")],
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "회원명",
            width: 90,
            field: "memberName",
            type: String,
            class: () => [this.$t("className.grid.clickArea")],
            cellClass: () => [this.$t("className.grid.clickArea")],
          },
          {
            minWidth: 16,
            name: "개인/법인",
            width: 90,
            field: "corporateDivisionComName",
            type: String,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "회원구분",
            width: 95,
            field: "memberDivisionComName",
            type: String,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "회원등급",
            width: 90,
            field: "memberGradeComName",
            type: String,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "VOC",
            width: 60,
            field: "vocCount",
            type: String,
            class: () => [this.$t("className.grid.clickArea")],
            cellClass: ({vocCount}) => [vocCount>0 && this.$t("className.grid.clickArea")],
            cellStyle: {
              textAlign: "center",
            },
            format:(data)=>{
              (data==="0")? data = '-' :  data = data+"건";
              return data;
            }
          },
          {
            minWidth: 16,
            name: "법인명",
            width: 120,
            field: "corporationName",
            type: String,
          },
          {
            minWidth: 16,
            name: "가입",
            width: 60,
            field: "entryOrderComName",
            type: String,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "성별",
            width: 65,
            field: "memberGenderComName",
            type: String,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "생년월일",
            width: 90,
            field: "memberBirthday",
            type: Date,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "웹 ID",
            width: 90,
            field: "webId",
            type: String,
            format: (webId) => {
              if (["KAKAO", "NAVER"].includes(webId?.split(":")?.[0])) {
                return webId?.split(":")?.[0];
              } else {
                return webId;
              }
            },
            class: () => [this.$t("className.grid.clickArea")],
            cellClass: () => [this.$t("className.grid.clickArea")],
          },
          {
            minWidth: 16,
            name: "핸드폰번호",
            width: 100,
            sortable: false,
            filterable: false,
            field: "memberPhoneNumber",
            type: String,
            format: FORMAT_TEL_CELLPHONE_NUMBER,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "지역민구분",
            width: 90,
            field: "nearbyCodeComName",
            type: String,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "분양일자",
            width: 90,
            field: "issueDate",
            type: Date,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "분양금액",
            width: 90,
            field: "issueAmt",
            type: Number,
            format: FORMAT_NUMBER,
            cellStyle: {
              textAlign: "right",
            },
          },
          {
            minWidth: 16,
            name: "취득일자",
            width: 90,
            field: "acquireDate",
            type: Date,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            sortable: true,
            filterable: true,
            minWidth: 16,
            name: "회원권그룹",
            width: 120,
            field: "membershipGroupCode",
            type: String,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "특전내용",
            width: 240,
            field: "benefitDescription",
            type: String,
          },
          {
            minWidth: 16,
            name: "예약비고",
            width: 240,
            field: "benResve",
            type: String,
          },
          {
            minWidth: 16,
            name: "회원권상태",
            width: 100,
            field: "membershipStatusComName",
            type: String,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "만기일자",
            width: 90,
            field: "expireDate",
            type: Date,
            cellStyle: ({ nearbyCodeComName, expireDate }) => ({
              color: nearbyCodeComName && expireDate && moment(expireDate).diff(this.now, 'days') <= 0
                ? 'red'
                : null,
              textAlign: "center",
            }),
          },
          {
            minWidth: 16,
            name: "지역구분",
            width: 90,
            field: "areaCodeComName",
            type: String,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "우편번호",
            width: 90,
            field: "homePostNo",
            type: String,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "주소",
            width: 250,
            field: "homeAddr1",
            type: String,
          },
          {
            minWidth: 16,
            name: "상세주소",
            width: 200,
            field: "homeAddr2",
            type: String,
          },
          {
            minWidth: 16,
            name: "그룹사",
            width: 90,
            field: "groupCompanyCodeComName",
            type: String,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "계열사",
            width: 90,
            field: "affltsCompanyCodeComName",
            type: String,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "직위",
            width: 90,
            field: "memberDetailsLevelName",
            type: String,
            cellStyle: {
              textAlign: "center",
            },
          },
          {
            minWidth: 16,
            name: "취득금액",
            width: 90,
            field: "acquireAmount",
            type: Number,
            format: FORMAT_NUMBER,
            cellStyle: {
              textAlign: "right",
            },
            visible: false
          },
          {
            minWidth: 16,
            name: "입금액",
            width: 90,
            field: "paidAmount",
            type: Number,
            format: FORMAT_NUMBER,
            cellStyle: {
              textAlign: "right",
            },
            visible: false
          },
          {
            minWidth: 16,
            name: "잔액",
            width: 90,
            field: "unpaidAmount",
            format: FORMAT_NUMBER,
            type: Number,
            cellStyle: {
              textAlign: "right",
            },
            visible: false
          },
          {
            minWidth: 16,
            name: "개서일자",
            width: 90,
            field: "rwrtDate",
            type: Date,
            cellStyle: {
              textAlign: "center",
            },
            visible: false
          },
        ],
        records: this.memberships,
        visibleRowNumberColumn: true,
        sortable: this.isSearched,
        filterable: this.isSearched,
        sizeOfRecordPerPage: 50,
        countOfAllRecords: this.totalMemberships,
        rangeOfPages: 10,
      };
    },
  },
  methods: {
    ...mapActions("documents", ["downloadExcelMemberRegistration"]),
    ...mapMutations([
      'SET_IS_RIGHT_VOC_ACTIVE',
      'SET_IS_LEFT_MENU_ACTIVE'
    ]),
    async initialize() {
      this.isLoading = true;
      try {
        this.now = (await GolfERPService.fetchNow()).format(DATE_FORMAT_YYYY_MM_DD);
      } finally {
        this.isLoading = false;
      }
    },
    openSearchMembersFilterOptionsPopup() {
      this.visibleSearchMembersFilterOptionsPopup = true;
      this.$nextTick(() => {
        this.$refs["search-members-filter-options-popup"].show();
      });
    },
    onClickRefresh() {
      if (!this.isSearched) {
        return;
      }

      this.searchMemberships();
    },
    onMemberAddClicked() {
      this.memberInfoMemberId = null;
      this.memberInfoMemberNo = null;

      this.visibleMemberInfoPopupOpen = true; // 팝업을 띄울 때 생성되도록 하기 위함
      this.$nextTick(() => {
        this.$refs.memberInputPopup.show();
      });
    },
    async onMemberGridCellClick(event, { column: { field }, record: { memberId,memberNumber,id,memberName,vocCount } } ) {
      if (field === "memberName" || field === "webId" || field === "memberNumber") {
        event.preventDefault();
        this.memberInfoMemberId = memberId;
        this.memberInfoMemberNo = memberNumber;

        this.visibleMemberInfoPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.memberInputPopup.show();
        });
      } else if(field === "vocCount" && vocCount>0) {
        await this.SET_IS_LEFT_MENU_ACTIVE(false);
        await this.SET_IS_RIGHT_VOC_ACTIVE(true);
        await this.$EventBus.$emit('vocButtonClick');
        this.$nextTick(() => {
          this.$EventBus.$emit("VOC",{id,memberName});
        });
      }
    },
    onMemberGridDataFiltered(event, { page, filters, orders }) {
      if (!event) {
        return;
      }

      this.searchMemberships({
        offset: (page - 1) * 50,
        filters,
        orders,
      });
    },
    onSearchMembersFilterOptionsPopupSubmit({ q, filterOptions }) {
      this.q = q;
      this.searchMembersFilterOptions = filterOptions;
      this.searchMemberships();
    },
    onSearchMembersFilterOptionsPopupClose() {
      this.visibleSearchMembersFilterOptionsPopup = false;
    },
    onCloseMemberInfoPopup({ mutated }) {
      this.visibleMemberInfoPopupOpen = false;
      this.memberInfoMemberId = null;
      this.memberInfoMemberNo = null;
      if (mutated) {
        this.searchMemberships();
      }
    },
    onPrintPaperClose() {
      this.visiblePrintPaper = false;
    },

    async onSearchClick() {
      this.$refs["membershipGrid"].resetPage();
      await this.searchMemberships();

      if (this.$refs["membershipGrid"].getSelectedRows().length < 1) {
        this.$refs["membershipGrid"].selectRow(0);
      }
    },

    async searchMemberships({ offset, filters, orders } = {}) {
      const {
        orders: gridOrders,
        filters: gridFilters,
        page: gridPage,
      } = this.$refs["membershipGrid"].getOrdersAndFiltersAndPage();

      const limit = this.membershipGridProps.sizeOfRecordPerPage;

      if (this.isLoading) {
        this.errorToast("데이터를 불러오는 중입니다.");
        return;
      }

      const _orders = JSON.parse(JSON.stringify(orders || gridOrders || []));
      if (_orders.findIndex(({ field }) => field === "memberNumber") < 0) {
        _orders.push({
          field: "memberNumber",
          direction: "asc",
        });
      }
      this.isLoading = true;
      try {
        const {
          total: totalMemberships,
          records: memberships,
        } = await GolfErpAPI.fetchRecordsAndCountMemberRegistration({
          q: this.q,
          corporationDivision:
            this.searchMembersFilterOptions.corporationDivision || undefined,
          memberDivision:
            this.searchMembersFilterOptions.memberDivision || undefined,
          memberGrade: this.searchMembersFilterOptions.memberGrade || undefined,
          corporationName:
            this.searchMembersFilterOptions.corporationName || undefined,
          endOfHpNumber:
            this.searchMembersFilterOptions.endOfHpNumber || undefined,
          webMemberId: this.searchMembersFilterOptions.webMemberId || undefined,
          groupCompanyCode:
            this.searchMembersFilterOptions.groupCompanyCode || undefined,
          affltsCompanyCode:
              this.searchMembersFilterOptions.affltsCompanyCode || undefined,
          smsReceiptDivision:
            this.searchMembersFilterOptions.smsReceiptDivision || undefined,
          membershipStatus:
            this.searchMembersFilterOptions.membershipStatus || undefined,
          acquireDateFrom:
            this.searchMembersFilterOptions.acquireDateFrom || undefined,
          acquireDateTo:
            this.searchMembersFilterOptions.acquireDateTo || undefined,
          nearbyCode: this.searchMembersFilterOptions.nearbyCode || undefined,
          expireDateFrom:
            this.searchMembersFilterOptions.expireDateFrom || undefined,
          expireDateTo:
            this.searchMembersFilterOptions.expireDateTo || undefined,
          birthday: this.searchMembersFilterOptions.birthday || undefined,
          gender: this.searchMembersFilterOptions.gender || undefined,
          isOut:
            this.searchMembersFilterOptions.isOut !== undefined
              ? this.searchMembersFilterOptions.isOut
              : undefined,
          limit,
          offset: offset || (gridPage - 1) * limit,
          filters: filters || gridFilters,
          orders: _orders,
        });
        this.totalMemberships = totalMemberships;
        this.memberships = memberships;
        this.isSearched = true;
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async print() {
      if (
        this.visibleSearchMembersFilterOptionsPopup ||
        this.visibleMemberInfoPopupOpen
      ) {
        return;
      }

      // if (50000 < this.totalMemberships) {
      //   return this.errorToast(
      //     "데이터가 너무 많습니다. 5만건 이하로 시도해주세요."
      //   );
      // }

      const searchOptionsList = [
        {
          key: this.labels.MemberName,
          value: this.q,
        },
        {
          key: this.labels.endOfHpNumber,
          value: this.searchMembersFilterOptions.endOfHpNumber,
        },
        {
          key: this.labels.corporationDivision,
          value: commonCodesGetComName(
            "CPR_DIV",
            this.searchMembersFilterOptions.corporationDivision
          ),
        },
        {
          key: this.labels.memberDivision,
          value: commonCodesGetComName(
            "MEMBER_DIV",
            this.searchMembersFilterOptions.memberDivision
          ),
        },
        {
          key: this.labels.memberGrade,
          value: commonCodesGetComName(
            "MEMBER_GRADE",
            this.searchMembersFilterOptions.memberGrade
          ),
        },
        {
          key: this.labels.corporationName,
          value: this.searchMembersFilterOptions.corporationName,
        },
        {
          key: this.labels.webMemberId,
          value: this.searchMembersFilterOptions.webMemberId,
        },
        {
          key: this.labels.groupCompanyCode,
          value: commonCodesGetComName(
            "GROUP_COMPANY_CODE",
            this.searchMembersFilterOptions.groupCompanyCode
          ),
        },
        {
          key: this.labels.affltsCompanyCode,
          value: commonCodesGetComName(
              "AFFLTS_COMPANY_CODE",
              this.searchMembersFilterOptions.affltsCompanyCode
          ),
        },
        {
          key: this.labels.smsReceiptDivision,
          value: commonCodesGetComName(
            "SMS_RECPTN_DIV",
            this.searchMembersFilterOptions.smsReceiptDivision
          ),
        },
        {
          key: this.labels.membershipStatus,
          value: commonCodesGetComName(
            "MEMBERSHIP_STATUS",
            this.searchMembersFilterOptions.membershipStatus
          ),
        },
        {
          key: this.labels.acquireDate,
          value:
            this.searchMembersFilterOptions.acquireDateFrom &&
            this.searchMembersFilterOptions.acquireDateTo
              ? this.searchMembersFilterOptions.acquireDateFrom +
                " ~ " +
                this.searchMembersFilterOptions.acquireDateTo
              : null,
        },
        {
          key: this.labels.nearbyCode,
          value: commonCodesGetComName(
              "NEARBY_CODE",
              this.searchMembersFilterOptions.nearbyCode
          ),
        },
        {
          key: this.labels.expireDate,
          value:
            this.searchMembersFilterOptions.expireDateFrom &&
            this.searchMembersFilterOptions.expireDateTo
              ? this.searchMembersFilterOptions.expireDateFrom +
              " ~ " +
              this.searchMembersFilterOptions.expireDateTo
              : null,
        },
        {
          key: this.labels.birthday,
          value: this.searchMembersFilterOptions.birthday,
        },
        {
          key: this.labels.gender,
          value: this.searchMembersFilterOptions.gender
            .replace("M", "남")
            .replace("F", "여"),
        },
        {
          key: this.labels.isOut,
          value:
            this.searchMembersFilterOptions.isOut !== ""
              ? this.searchMembersFilterOptions.isOut
                ? "탈퇴회원"
                : "회원"
              : null,
        },
      ];

      const searchOptions = searchOptionsList
        .filter((item) => !!item.value)
        .map((item) => item.key + ": " + item.value)
        .join(", ");

      const {
        orders: gridOrders,
        filters: gridFilters,
        page: gridPage,
      } = this.$refs["membershipGrid"].getOrdersAndFiltersAndPage();

      const limit = this.membershipGridProps.sizeOfRecordPerPage;

      const reportData = {
        reportJson: {
          username: this.username,
          uniqueCode: this.$options.name,
          searchOptions,
        },
        filterSort: {
          q: this.q,
          corporationDivision:
            this.searchMembersFilterOptions.corporationDivision || undefined,
          memberDivision:
            this.searchMembersFilterOptions.memberDivision || undefined,
          memberGrade: this.searchMembersFilterOptions.memberGrade || undefined,
          corporationName:
            this.searchMembersFilterOptions.corporationName || undefined,
          endOfHpNumber:
            this.searchMembersFilterOptions.endOfHpNumber || undefined,
          webMemberId: this.searchMembersFilterOptions.webMemberId || undefined,
          groupCompanyCode:
            this.searchMembersFilterOptions.groupCompanyCode || undefined,
          affltsCompanyCode:
              this.searchMembersFilterOptions.affltsCompanyCode || undefined,
          smsReceiptDivision:
            this.searchMembersFilterOptions.smsReceiptDivision || undefined,
          membershipStatus:
            this.searchMembersFilterOptions.membershipStatus || undefined,
          acquireDateFrom:
            this.searchMembersFilterOptions.acquireDateFrom || undefined,
          acquireDateTo:
            this.searchMembersFilterOptions.acquireDateTo || undefined,
          nearbyCode: this.searchMembersFilterOptions.nearbyCode || undefined,
          expireDateFrom:
            this.searchMembersFilterOptions.expireDateFrom || undefined,
          expireDateTo:
            this.searchMembersFilterOptions.expireDateTo || undefined,
          birthday: this.searchMembersFilterOptions.birthday || undefined,
          gender: this.searchMembersFilterOptions.gender || undefined,
          isOut: this.searchMembersFilterOptions.isOut || undefined,
          limit,
          offset: (gridPage - 1) * limit,
          filters: gridFilters,
          orders: gridOrders,
        },
      };

      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
    async onExcelClick() {
      if (50000 < this.totalMemberships) {
        this.errorToast("데이터가 너무 많습니다. 5만건 이하로 시도해주세요.");
        return;
      }

      const { orders: gridOrders, filters: gridFilters } = this.$refs[
        "membershipGrid"
      ].getOrdersAndFiltersAndPage();
      const columns = this.$refs["membershipGrid"].getColumns();

      const _orders = JSON.parse(JSON.stringify(gridOrders || []));
      if (_orders.findIndex(({ field }) => field === "memberName") < 0) {
        _orders.push({
          field: "memberName",
          direction: "asc",
        });
      }

      this.downloadExcelMemberRegistration({
        payload: {
          q: this.q,
          corporationDivision:
            this.searchMembersFilterOptions.corporationDivision || undefined,
          memberDivision:
            this.searchMembersFilterOptions.memberDivision || undefined,
          memberGrade: this.searchMembersFilterOptions.memberGrade || undefined,
          corporationName:
            this.searchMembersFilterOptions.corporationName || undefined,
          endOfHpNumber:
            this.searchMembersFilterOptions.endOfHpNumber || undefined,
          webMemberId: this.searchMembersFilterOptions.webMemberId || undefined,
          groupCompanyCode:
            this.searchMembersFilterOptions.groupCompanyCode || undefined,
          affltsCompanyCode:
              this.searchMembersFilterOptions.affltsCompanyCode || undefined,
          smsReceiptDivision:
            this.searchMembersFilterOptions.smsReceiptDivision || undefined,
          membershipStatus:
            this.searchMembersFilterOptions.membershipStatus || undefined,
          acquireDateFrom:
            this.searchMembersFilterOptions.acquireDateFrom || undefined,
          acquireDateTo:
            this.searchMembersFilterOptions.acquireDateTo || undefined,
          nearbyCode: this.searchMembersFilterOptions.nearbyCode || undefined,
          expireDateFrom:
            this.searchMembersFilterOptions.expireDateFrom || undefined,
          expireDateTo:
            this.searchMembersFilterOptions.expireDateTo || undefined,
          birthday: this.searchMembersFilterOptions.birthday || undefined,
          gender: this.searchMembersFilterOptions.gender || undefined,
          isOut:
            this.searchMembersFilterOptions.isOut !== undefined
              ? this.searchMembersFilterOptions.isOut
              : undefined,
          filters: gridFilters,
          orders: _orders,
          columns,
        },
      });
    },
  },
};
</script>
