import request from "@/utils/request";

/**
 * @return Promise
 */
export function getBizInfo(searchDiv, searchContent) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/biz-code/biz-info",
    method: "get",
    params: {
      searchContent,
      searchDiv,
    },
  });
}

/**
 * @return Promise
 */
export function postBizInfo(tsBizMstRequest) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/biz-code/biz-info",
    method: "post",
    data: tsBizMstRequest,
  });
}

/**
 * @return Promise
 */
export function putBizInfo(tsBizMstRequest) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/biz-code/biz-info",
    method: "put",
    data: tsBizMstRequest,
  });
}

/**
 * @return Promise
 */
export function deleteBizInfo(bizMstCode, deleteFlag) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/biz-code/biz-info",
    method: "delete",
    params: {
      bizMstCode,
      deleteFlag,
    },
  });
}

/**
 * @return Promise
 */
export function getBsnInfo(bizMstCode) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/biz-code/bsn-info",
    method: "get",
    params: {
      bizMstCode,
    },
  });
}

/**
 * @return Promise
 */
export function postBsnInfo(tsBsnMstRequest) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/biz-code/bsn-info",
    method: "post",
    data: tsBsnMstRequest,
  });
}

/**
 * @return Promise
 */
export function putBsnInfo(tsBsnMstRequest) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/biz-code/bsn-info",
    method: "put",
    data: tsBsnMstRequest,
  });
}

/**
 * @return Promise
 */
export function deleteBsnInfo(bizMstCode, bsnId, deleteFlag) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/biz-code/bsn-info",
    method: "delete",
    params: {
      bizMstCode,
      bsnId,
      deleteFlag,
    },
  });
}

/**
 * @return Promise
 */
export function getStoreBsnInfo(bizMstCode, bsnId) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/biz-code/store-bsn-info",
    method: "get",
    params: {
      bizMstCode,
      bsnId,
    },
  });
}

/**
 * @return Promise
 */
export function putStoreBsnInfo(tsStoreBsnRequest) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/biz-code/store-bsn-info",
    method: "put",
    data: tsStoreBsnRequest,
  });
}

/**
 *
 * @param {string} storeCode - 매장코드
 * @returns Promise
 */
export function getStoreBsnMstInfo(storeCode) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/code_management/biz-code/store-bsn-mst/${storeCode}`,
    method: "get",
  });
}
