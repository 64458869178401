<template>
  <div>
    <ejs-dialog
        ref="memberAnnualFeeRegistrationPopup"
        header="연회비 등록"
        :allowDragging="true"
        :showCloseIcon="true"
        width="1180"
        height="800"
        :isModal="true"
        @close="onMemberAnnualFeeRegistrationPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">회원번호 :</div>
                    <ul class="content">
                      <li class="title"> {{ memberNo }}</li>
                      <li class="field">
                        <div class="title">회원명 :</div>
                        <ul class="content">
                          <li class="title">{{ memberName }}</li>
                        </ul>
                      </li>
                      <li class="field">
                        <div class="title">회원구분 :</div>
                        <ul class="content">
                          <li class="title">{{ memberDiv }}</li>
                        </ul>
                      </li>
                      <li class="field">
                        <div class="title">법인구분 :</div>
                        <ul class="content">
                          <li class="title">{{ cprDiv }}</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul class="lookup-button">
                  <li class="lookup keyColor">
                    <erp-button
                        buttonDiv="GET"
                        :isShortcutButton="true"
                        @click.native="onClickMembershipAnnualFeeSearch"
                    >
                      조회
                    </erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article"
                       :class="isMemberAnnualFeeDetailViewOpened
                        ? $t('className.reservation.reservationDetailViewOpened'): '' "
              >
                <div class="article-left">
                  <section class="article-section section-01">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">회비입금 내역</div>
                        <div class="header-caption">
                          [ {{ totalMembershipFeeDeposit | numberWithCommas }} 건 ]
                        </div>
                      </div>
                      <div class="header-right">
                        <ul class="header-button">
                          <li class="add">
                            <erp-button
                                button-div="SAVE"
                                :is-icon-custom="true"
                                :is-custom-shortcut-button="true"
                                shortcut-key="memberAnnualFeeRegistrationPopup.shortcuts.save"
                                :shortcut="{key: 'F3'}"
                                @click.native="onClickMembershipAnnualFeeDepositAdd"
                            >
                              추가
                            </erp-button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="section-body">
                      <ejs-grid-wrapper
                          ref="MembershipFeeDepositDetailsGrid"
                          :columns="membershipFeeDepositListGridColumn"
                          :dataSource="membershipFeeDepositList"
                          :selectionSettings="selectionSettings"
                          :provides="grid"
                          :allowPaging="true"
                          :pageSettings="membershipFeeDepositListPageSettings"
                          :isAutoSelectRow="false"
                          :isSelectedRowRetain="false"
                          @headerCellInfo="membershipFeeDepositHeaderCellInfo"
                          @queryCellInfo="onMembershipFeeDepositQueryCellInfo"
                          @rowSelected="onMembershipFeeDepositGridRowSelected"
                      />
                    </div>
                  </section>
                </div>
                <div class="article-right">
                  <section class="article-section section-02">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">회비입금 상세 정보</div>
                      </div>
                    </div>
                    <div class="section-caption">
                      <ul class="caption-button">
                        <li class="save keyColor">
                          <erp-button
                              button-div="SAVE"
                              :is-key-color="true"
                              :is-shortcut-button="true"
                              :ignore="!isMemberAnnualFeeDetailViewOpened"
                              @click.native="onClickMemberAnnualFeeDetailSave">
                            저장
                          </erp-button>
                        </li>
                        <li class="delete">
                          <erp-button
                              button-div="DELETE"
                              :is-shortcut-button="true"
                              :ignore="!isMemberAnnualFeeDetailViewOpened"
                              @click.native="onClickMemberAnnualFeeDetailDelete"
                          >
                            삭제
                          </erp-button>
                        </li>
                        <li class="close">
                          <erp-button
                              buttonDiv="CLOSE"
                              @click.native="onMemberAnnualFeeDetailViewClosed"
                              :isIconCustom="true"
                          >
                            닫기
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                    <div class="section-body" v-show="isMemberAnnualFeeDetailViewOpened">
                      <article class="body-article">
                        <!-- 아코디언 : accordion / 닫힘 : close -->
                        <section class="article-section section-0201">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <div class="title required">입금일자</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-date v-model="annualFeeDepositInfo.depositDate"/>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">회비구분</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                ref="annualFeeDiv"
                                                placeholder="선택"
                                                v-model="annualFeeDepositInfo.annualFeeDiv"
                                                :dataSource="commonCodeOptions.annualFeeDiv"
                                                :fields="commonCodeOptions.fields"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">입금액</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-number
                                                ref="depositAmt"
                                                v-model="annualFeeDepositInfo.depositAmt"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">지불방법</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                ref="payDiv"
                                                placeholder="선택"
                                                v-model="annualFeeDepositInfo.payDiv"
                                                :dataSource="commonCodeOptions.payDiv"
                                                :fields="commonCodeOptions.fields"
                                                @change="onChangePayDiv"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title"
                                         :class="annualFeeDepositInfo.payDiv === PAY_DIV.TRANS ? 'required' : ''"
                                    >
                                      입금은행
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                ref="bankCode"
                                                placeholder="선택"
                                                v-model="annualFeeDepositInfo.bankCode"
                                                :dataSource="commonCodeOptions.bankCode"
                                                :fields="commonCodeOptions.fields"
                                                :enabled="annualFeeDepositInfo.payDiv === PAY_DIV.TRANS"
                                                cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title"
                                         :class="annualFeeDepositInfo.payDiv === PAY_DIV.TRANS ? 'required' : ''"
                                    >계좌번호
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                                ref="acnutNo"
                                                v-model="annualFeeDepositInfo.acnutNo"
                                                :disabled="annualFeeDepositInfo.payDiv !== PAY_DIV.TRANS"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title"
                                         :class="annualFeeDepositInfo.payDiv === PAY_DIV.CARD ? 'required' : ''"
                                    >카드사
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                ref="purcId"
                                                placeholder="선택"
                                                v-model="annualFeeDepositInfo.purcId"
                                                :dataSource="commonCodeOptions.purcId"
                                                :fields="commonCodeOptions.purcIdFields"
                                                :enabled="annualFeeDepositInfo.payDiv === PAY_DIV.CARD"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title"
                                         :class="annualFeeDepositInfo.payDiv === PAY_DIV.CARD ? 'required' : ''"
                                    >카드번호
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                                ref="cardNo"
                                                v-model="annualFeeDepositInfo.cardNo"
                                                :disabled="annualFeeDepositInfo.payDiv !== PAY_DIV.CARD"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title"
                                         :class="annualFeeDepositInfo.payDiv === PAY_DIV.CARD ? 'required' : ''"
                                    >카드유효기간
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-maskedtextbox
                                                ref="validTerm"
                                                class="e-input"
                                                type="text"
                                                v-model="annualFeeDepositInfo.validTerm"
                                                placeholder="MM/YY"
                                                mask="00/00"
                                                :enabled="annualFeeDepositInfo.payDiv === PAY_DIV.CARD"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title"
                                         :class="annualFeeDepositInfo.payDiv === PAY_DIV.CARD ? 'required' : ''"
                                    >승인번호
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                                ref="approvalNo"
                                                v-model="annualFeeDepositInfo.approvalNo"
                                                :disabled="annualFeeDepositInfo.payDiv !== PAY_DIV.CARD"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">비고</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                                ref="remarks"
                                                v-model="annualFeeDepositInfo.remarks"/>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">유효기간</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-date-range
                                                ref="expirDateRange"
                                                type="lookup-condition"
                                                v-model="annualFeeDepositInfo.expirDateRange"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </section>
                      </article>
                    </div>
                  </section>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="onMemberAnnualFeeRegistrationPopupClosed"
                  :isIconCustom="true"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>
<script>
import ErpButton from "@/components/button/ErpButton.vue";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import commonMixin from "@/views/layout/mixin/commonMixin";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {numberWithCommas} from "@/utils/number";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {commonCodesGetCommonCode, commonCodesGetCommonCodeByIdxAttrb, commonCodesGetComName} from "@/utils/commonCodes";
import {memberNoFormatter} from "@/utils/formatter";
import {
  Aggregate,
  ExcelExport,
  Filter,
  ForeignKey,
  Group,
  Page,
  Resize,
  VirtualScroll
} from "@syncfusion/ej2-vue-grids";
import {gridUtilGetMemberNoColumnAccess} from "@/utils/gridUtil";
import InputText from "@/components/common/text/InputText.vue";
import InputDateRange from "@/components/common/datetime/InputDateRange.vue";
import InputNumber from "@/components/common/InputNumber.vue";
import InputDate from "@/components/common/datetime/InputDate.vue";
import {getFormattedDate} from "@/utils/date";
import {getStoreBsnMstInfo} from "@/api/bizCodeManagement";
import GolfERPService from "@/service/GolfERPService";
import { validateFormRefs } from "@/utils/formUtil";

export default {
  name: "MemberAnnualFeeRegistrationPopup",
  components: {
    InputDate,
    InputNumber,
    InputDateRange,
    InputText,
    ejsGridWrapper,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  filters: {
    numberWithCommas,
  },
  props: [],
  created() {
  },
  data() {
    return {
      PAY_DIV: {
        CARD: "CARD",
        TRANS: "TRANS",
        CASH: "CASH",
      },
      membershipId: null,
      memberNo: null,
      memberName: "",
      memberDiv: "",
      cprDiv: "",
      totalMembershipFeeDeposit: 0,
      isMemberAnnualFeeDetailViewOpened: false,
      grid: [
        Aggregate,
        Filter,
        Resize,
        Group,
        ExcelExport,
        ForeignKey,
        VirtualScroll,
        Page,
      ],
      membershipFeeDepositListPageSettings: {pageSize: 25},
      selectionSettings: {
        type: "Single",
        mode: "Both",
        enableToggle: false,
        persistSelection: false,
      },
      membershipFeeDepositList: [],
      membershipFeeDepositListGridColumn: [
        {
          field: 'annualFeeId',
          type: 'string',
          isPrimaryKey: true,
          visible: false,
        },
        {
          field: 'memberNo',
          headerText: '회원번호',
          type: 'string',
          valueAccessor: (field, data) =>
              gridUtilGetMemberNoColumnAccess(field, data),
          width: 95,
          textAlign: 'center',
        },
        {
          field: 'annualFeeDiv',
          headerText: '회비구분',
          type: 'string',
          width: 110,
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: "ANNUAL_FEE_DIV",
        },
        {
          field: 'depositDate',
          headerText: '입금일자',
          type: Date,
          width: 110,
          textAlign: 'center',
        },
        {
          field: 'depositAmt',
          headerText: '입금액',
          type: Number,
          width: 110,
          textAlign: 'right',
          isNumericType: true,
        },
        {
          field: 'expirDateFrom',
          headerText: '유효기간(From)',
          type: Date,
          width: 125,
          textAlign: 'center',
        },
        {
          field: 'expirDateTo',
          headerText: '유효기간(to)',
          type: Date,
          width: 110,
          textAlign: 'center',
        },
        {
          field: 'payDiv',
          headerText: '지불방법',
          type: 'string',
          width: 85,
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: "PAY_DIV",
        },
        {
          field: 'remarks',
          headerText: '비고',
          type: String,
          width: 260,
          textAlign: 'center',
        },
      ],
      orgAnnualFeeDepositInfo: {
        annualFeeId: null,     // 연회비ID
        membershipId: null, // 회원권ID
        depositDate: null,  // 입금일자
        annualFeeDiv: null, // 회비구분
        depositAmt: 0,   // 입금액
        payDiv: null,       // 지불방법
        bankCode: null,     // 입금은행
        acnutNo: null,      // 계좌번호
        purcId: 0,          // 카드사 (매입처ID)
        cardNo: null,       // 카드번호
        validTerm: "",    // 카드유효기간
        approvalNo: null,   // 승인번호
        remarks: null,      // 비고
        expirDateRange: {
          from: null, // 유효기간 From (expirDateFrom)
          to: null,  // 유효기간 To (expirDateTo)
        }
      },
      annualFeeDepositInfo: {
        annualFeeId: null,     // 연회비ID
        membershipId: null, // 회원권ID
        depositDate: null,  // 입금일자
        annualFeeDiv: null, // 회비구분
        depositAmt: 0,   // 입금액
        payDiv: null,       // 지불방법
        bankCode: null,     // 입금은행
        acnutNo: null,      // 계좌번호
        purcId: 0,          // 카드사 (매입처ID)
        cardNo: null,       // 카드번호
        validTerm: "",    // 카드유효기간
        approvalNo: null,   // 승인번호
        remarks: null,      // 비고
        expirDateRange: {
          from: null, // 유효기간 From (expirDateFrom)
          to: null,  // 유효기간 To (expirDateTo)
        }
      },
      //드롭다운 코드
      commonCodeOptions: {
        annualFeeDiv: commonCodesGetCommonCode("ANNUAL_FEE_DIV"),
        payDiv: commonCodesGetCommonCodeByIdxAttrb("PAY_DIV", 2, "Y"),
        bankCode: commonCodesGetCommonCode("BANK_CODE"),
        purcId: null,
        fields: {text: "comName", value: "comCode"},
        purcIdFields: {text: "purchaseName", value: "purchaseId"},
      },
      annualFeeDepositValidationRule1: {
        depositDate: {
          required: true,
        },
        annualFeeDiv: {
          required: true,
        },
        depositAmt: {
          required: true,
          min: 1,
        },
        payDiv: {
          required: true,
        },
        expirDateRange: {
          required: true,
        }
      },
      annualFeeDepositValidationRules2: {
        bankCode: {
          required: true,
        },
        acnutNo: {
          required: true,
        },
      },
      annualFeeDepositValidationRules3: {
        purcId: {
          required: true,
        },
        cardNo: {
          required: true,
        },
        validTerm: {
          required: true,
        },
        approvalNo: {
          required: true,
        },
      },
    };
  },
  computed: {},
  methods: {
    validateFormRefs,
    async showPopup(popupData = {}) {
      const { membershipId } = popupData;
      this.membershipId = membershipId;

      await this.fetchMembershipAnnualFee();
      await this.setCommonCodeOptionsPurcId();
    },
    async setCommonCodeOptionsPurcId() {
      const {value: bsnInfo} = await getStoreBsnMstInfo("FRONT");
      const placesOfCardPurchase = await GolfERPService.getPlacesOfCardPurchase(
          bsnInfo.vanKind
      );
      this.commonCodeOptions.purcId = placesOfCardPurchase.map(
          ({purchaseId, purchaseName, vanMappingList}) => {
            return {
              purchaseId: purchaseId,
              purchaseName: purchaseName,
              vanMapCode: vanMappingList[0].vanMapCode,
            };
          }
      );
    },
    init() {
      const currentYearDate = new Date();
      const nextYearDate = new Date(currentYearDate.setFullYear(currentYearDate.getFullYear() + 1));

      this.annualFeeDepositInfo.annualFeeId = null;
      this.annualFeeDepositInfo.membershipId = null;
      this.annualFeeDepositInfo.depositDate = getFormattedDate(new Date());
      this.annualFeeDepositInfo.annualFeeDiv = null;
      this.annualFeeDepositInfo.depositAmt = 0;
      this.annualFeeDepositInfo.payDiv = null;
      this.annualFeeDepositInfo.bankCode = null;
      this.annualFeeDepositInfo.acnutNo = null;
      this.annualFeeDepositInfo.purcId = null;
      this.annualFeeDepositInfo.cardNo = null;
      this.annualFeeDepositInfo.validTerm = "";
      this.annualFeeDepositInfo.approvalNo = null;
      this.annualFeeDepositInfo.remarks = null;
      this.annualFeeDepositInfo.expirDateRange.from = getFormattedDate(new Date());
      this.annualFeeDepositInfo.expirDateRange.to = getFormattedDate(nextYearDate);

      this.orgAnnualFeeDepositInfo.annualFeeId = null;
      this.orgAnnualFeeDepositInfo.membershipId = null;
      this.orgAnnualFeeDepositInfo.depositDate = getFormattedDate(new Date());
      this.orgAnnualFeeDepositInfo.annualFeeDiv = null;
      this.orgAnnualFeeDepositInfo.depositAmt = 0;
      this.orgAnnualFeeDepositInfo.payDiv = null;
      this.orgAnnualFeeDepositInfo.bankCode = null;
      this.orgAnnualFeeDepositInfo.acnutNo = null;
      this.orgAnnualFeeDepositInfo.purcId = null;
      this.orgAnnualFeeDepositInfo.cardNo = null;
      this.orgAnnualFeeDepositInfo.validTerm = "";
      this.orgAnnualFeeDepositInfo.approvalNo = null;
      this.orgAnnualFeeDepositInfo.remarks = null;
      this.orgAnnualFeeDepositInfo.expirDateRange.from = getFormattedDate(new Date());
      this.orgAnnualFeeDepositInfo.expirDateRange.to = getFormattedDate(nextYearDate);
    },
    async fetchMembershipAnnualFee() {
      const {
        memberNo,
        memberDiv,
        cprDiv,
        tgMembershipAnnualFees,
        tgMember: {memberName}
      } = await GolfErpAPI.fetchMembershipAnnualFee({
        membershipId: this.membershipId
      });
      this.memberNo = memberNoFormatter(memberNo);
      this.memberName = memberName;
      this.memberDiv = commonCodesGetComName("MEMBER_DIV", memberDiv);
      this.cprDiv = commonCodesGetComName("CPR_DIV", cprDiv);
      this.totalMembershipFeeDeposit = tgMembershipAnnualFees.length ? tgMembershipAnnualFees.length : 0;

      tgMembershipAnnualFees.map(item => item.memberNo = memberNo);
      this.membershipFeeDepositList = tgMembershipAnnualFees;
    },
    async fetchMembershipAnnualFeeDetail(annualFeeId) {
      const {
        annualFeeDiv,
        depositAmt,
        depositDate,
        bankCode,
        acnutNo,
        purcId,
        cardNo,
        validTerm,
        approvalNo,
        remarks,
        expirDateFrom,
        expirDateTo,
        membershipId,
        payDiv
      } = await GolfErpAPI.fetchMembershipAnnualFeeDetail({annualFeeId: annualFeeId});

      this.annualFeeDepositInfo.annualFeeId = annualFeeId;
      this.annualFeeDepositInfo.membershipId = membershipId === undefined ? null : membershipId;
      this.annualFeeDepositInfo.depositDate = depositDate === undefined ? null : depositDate;
      this.annualFeeDepositInfo.annualFeeDiv = annualFeeDiv === undefined ? null : annualFeeDiv;
      this.annualFeeDepositInfo.depositAmt = depositAmt === undefined ? null : depositAmt;
      this.annualFeeDepositInfo.payDiv = payDiv === undefined ? null : payDiv;
      this.annualFeeDepositInfo.bankCode = bankCode === undefined ? null : bankCode;
      this.annualFeeDepositInfo.acnutNo = acnutNo === undefined ? null : acnutNo;
      this.annualFeeDepositInfo.purcId = purcId === undefined ? null : purcId;
      this.annualFeeDepositInfo.cardNo = cardNo === undefined ? null : cardNo;
      this.annualFeeDepositInfo.validTerm = validTerm === undefined ? "" : validTerm;
      this.annualFeeDepositInfo.approvalNo = approvalNo === undefined ? null : approvalNo;
      this.annualFeeDepositInfo.remarks = remarks === undefined ? null : remarks;
      this.annualFeeDepositInfo.expirDateRange.from = expirDateFrom === undefined ? null : getFormattedDate(expirDateFrom);
      this.annualFeeDepositInfo.expirDateRange.to = expirDateTo === undefined ? null : getFormattedDate(expirDateTo);

      this.orgAnnualFeeDepositInfo = JSON.parse(JSON.stringify(this.annualFeeDepositInfo));
    },
    async onClickMembershipAnnualFeeSearch() {
      const { memberNo, tgMembershipAnnualFees } = await GolfErpAPI.fetchMembershipAnnualFee({
        membershipId: this.membershipId
      });
      this.totalMembershipFeeDeposit = tgMembershipAnnualFees.length ? tgMembershipAnnualFees.length : 0;
      tgMembershipAnnualFees.map(item => item.memberNo = memberNo);
      this.membershipFeeDepositList = tgMembershipAnnualFees;

      this.init();
      this.isMemberAnnualFeeDetailViewOpened = false;
    },
    async onClickMembershipAnnualFeeDepositAdd() {
      if (
          JSON.stringify(this.annualFeeDepositInfo) !== JSON.stringify(this.orgAnnualFeeDepositInfo)
      ) {
        if (
            !(await this.confirm("수정된 내용이 있습니다. 초기화하시겠습니까?"))
        ) {
          return;
        }
      }
      this.isMemberAnnualFeeDetailViewOpened = true;
      this.init();
    },
    onChangePayDiv() {
      if (this.annualFeeDepositInfo.payDiv === this.PAY_DIV.CARD) {
        this.annualFeeDepositInfo.bankCode = null;
        this.annualFeeDepositInfo.acnutNo = null;
      }
      if (this.annualFeeDepositInfo.payDiv === this.PAY_DIV.TRANS) {
        this.annualFeeDepositInfo.cardNo = null;
        this.annualFeeDepositInfo.purcId = null;
        this.annualFeeDepositInfo.validTerm = null;
        this.annualFeeDepositInfo.approvalNo  = null;
      }
      if(this.annualFeeDepositInfo.payDiv === this.PAY_DIV.CASH) {
        this.annualFeeDepositInfo.bankCode = null;
        this.annualFeeDepositInfo.acnutNo = null;
        this.annualFeeDepositInfo.cardNo = null;
        this.annualFeeDepositInfo.purcId = null;
        this.annualFeeDepositInfo.validTerm = null;
        this.annualFeeDepositInfo.approvalNo  = null;
      }
    },
    onMemberAnnualFeeRegistrationPopupClosed() {
      this.$emit("popupClosed");
      this.init();
      this.$refs.memberAnnualFeeRegistrationPopup.hide();
    },
    membershipFeeDepositHeaderCellInfo(args) {
      const {cell: {column: {field}}, node: {classList}} = args;
      if (field === "depositAmt") {
        classList.add(this.$t("className.grid.clickAreaLeft"));
      }
    },
    onMembershipFeeDepositQueryCellInfo(args) {
      const {
        column: {field},
        cell,
      } = args;
      if (field === "depositAmt") {
        cell.classList.add(this.$t("className.grid.clickAreaLeft"));
      }
    },
    async onMembershipFeeDepositGridRowSelected(args) {
      const annualFeeId = args.data.annualFeeId;
      await this.fetchMembershipAnnualFeeDetail(annualFeeId);
      this.isMemberAnnualFeeDetailViewOpened = true;
    },
    async onClickMemberAnnualFeeDetailSave() {
      if (!this.validateFormRefs(this.annualFeeDepositValidationRule1)) {
        return;
      }
      if (this.annualFeeDepositInfo.payDiv === this.PAY_DIV.TRANS) {
        if (!this.validateFormRefs(this.annualFeeDepositValidationRules2)) {
          return;
        }
      }
      if (this.annualFeeDepositInfo.payDiv === this.PAY_DIV.CARD) {
        if (!this.validateFormRefs(this.annualFeeDepositValidationRules3)) {
          return;
        }
      }

      try {
        const savedAnnualFeeId = await GolfErpAPI.membershipAnnualFeeSave({
          membershipId: !this.annualFeeDepositInfo.membershipId ? this.membershipId : this.annualFeeDepositInfo.membershipId,
          annualFeeId: !this.annualFeeDepositInfo.annualFeeId ? null : this.annualFeeDepositInfo.annualFeeId,
          depositDate: this.annualFeeDepositInfo.depositDate,
          annualFeeDiv: this.annualFeeDepositInfo.annualFeeDiv,
          depositAmt: this.annualFeeDepositInfo.depositAmt,
          payDiv: this.annualFeeDepositInfo.payDiv,
          bankCode: !this.annualFeeDepositInfo.bankCode ? null : this.annualFeeDepositInfo.bankCode,
          acnutNo: !this.annualFeeDepositInfo.acnutNo ? null : this.annualFeeDepositInfo.acnutNo,
          purcId: !this.annualFeeDepositInfo.purcId ? null : this.annualFeeDepositInfo.purcId,
          cardNo: !this.annualFeeDepositInfo.cardNo ? null : this.annualFeeDepositInfo.cardNo,
          validTerm: !this.annualFeeDepositInfo.validTerm ? null : this.annualFeeDepositInfo.validTerm,
          approvalNo: !this.annualFeeDepositInfo.approvalNo ? null : this.annualFeeDepositInfo.approvalNo,
          remarks: !this.annualFeeDepositInfo.remarks ? null : this.annualFeeDepositInfo.remarks,
          expirDateTo: this.annualFeeDepositInfo.expirDateRange.to,
          expirDateFrom: this.annualFeeDepositInfo.expirDateRange.from,
        });

        if (this.annualFeeDepositInfo.annualFeeId) {
          await this.infoToast("수정되었습니다.");
        } else {
          this.annualFeeDepositInfo.annualFeeId = savedAnnualFeeId;
          await this.infoToast("저장되었습니다.");
        }

        this.orgAnnualFeeDepositInfo = JSON.parse(JSON.stringify(this.annualFeeDepositInfo));
        await this.fetchMembershipAnnualFee();

      } catch (e) {
        console.log(e);
      }
    },
    async onClickMemberAnnualFeeDetailDelete() {
      if (!this.annualFeeDepositInfo.annualFeeId) {
        await this.errorToast(`회비입금 상세 정보가 없습니다. 신규 저장 또는 재조회 해주시기 바랍니다.`);
        return;
      }

      if (!await this.confirm("정말로 삭제하시겠습니까?")) {
        return;
      }
      try {
        await GolfErpAPI.deleteMembershipAnnualFee({
          annualFeeId: this.annualFeeDepositInfo.annualFeeId
        });
        await this.infoToast("삭제되었습니다.");
        await this.init();
        this.isMemberAnnualFeeDetailViewOpened = false;
        await this.fetchMembershipAnnualFee();
      } catch (e) {
        console.log(e);
      }

    },
    onMemberAnnualFeeDetailViewClosed() {
      this.init();
      this.isMemberAnnualFeeDetailViewOpened = false;
    },
  },
};
</script>

<style scoped>
.body-article .article-left, .body-article .article-right {
  transition: all .3s ease-out
}

.body-article .article-left {
  width: 100%
}

.body-article .article-right {
  width: 400px;
  margin: 0 -400px 0 0
}

.body-article.dev-reservation-detail-view-opened .article-left {
  width: calc(100% - 400px)
}

.body-article.dev-reservation-detail-view-opened .article-right {
  margin: 0;
}

.article-section.section-01 .section-body {
  overflow: hidden;
  border: none;
}

.article-section.section-02 .body-article{
  height:auto
}
.article-section.section-02 .body-article .article-section{height:auto}
.article-section.section-02 .body-article .section-header{width:auto}
.article-section.section-02 .body-article .section-body{overflow:visible;border:none}

.article-section.section-02 .section-body .data-content li {
  width: 100%;
}

.article-section.section-02 .body-article {
  height: auto;
}

.article-section.section-02 .body-article .article-section {
  height: auto
}

.article-section.section-02 .body-article .section-header {
  width: auto
}

.article-section.section-02 .body-article .section-body {
  overflow: visible
}
</style>