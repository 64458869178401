import { isDateFormatString, isTimeFormatString } from "@/utils/string";

function testRequired(value) {
  if (value === undefined || value === null) {
    return false;
  }

  if (typeof value === "string") {
    return 0 < value.trim().length;
  }

  return true;
}

function testLength(value, length) {
  if (value === undefined || value === null || value === "") {
    return true;
  }

  if (typeof value !== "string") {
    throw new Error("string validation error: not string type.");
  }

  return value.trim().length === length;
}

function testMinLength(value, minLength) {
  if (value === undefined || value === null) {
    return true;
  }

  if (typeof value !== "string") {
    throw new Error("string validation error: not string type.");
  }

  return !(value.trim().length < minLength);
}

function testMaxLength(value, maxLength) {
  if (value === undefined || value === null) {
    return true;
  }

  if (typeof value !== "string") {
    throw new Error("string validation error: not string type.");
  }

  return !(maxLength < value.trim().length);
}

function testMin(value, min) {
  if (value === undefined || value === null) {
    return true;
  }

  if (typeof value !== "number") {
    throw new Error("number validation error: not number type.");
  }

  return !(value < min);
}

function testMax(value, max) {
  if (value === undefined || value === null) {
    return true;
  }

  if (typeof value !== "number") {
    throw new Error("number validation error: not number type.");
  }

  return !(max < value);
}

function testArray(value) {
  if (value === undefined || value === null) {
    return true;
  }

  return Array.isArray(value);
}

function testJson(value) {
  if (value === undefined || value === null) {
    return true;
  }

  return typeof value === "object" && !Array.isArray(value);
}

const STRING_RULES = {
  required: testRequired,
  length: testLength,
  "min-length": testMinLength,
  "max-length": testMaxLength,
  email: (value) =>
    value === undefined || value === null || value === ""
      ? true
      // eslint-disable-next-line no-control-regex
      : /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
          value
        ),
  telephone: (value) =>
    value === undefined || value === null
      ? true
      : /^((01[016789])([0-9]{3,4})([0-9]{4})|)$/.test(value),
  date: (value) =>
    value === undefined || value === null || value === ""
      ? true
      : isDateFormatString(value),
  time: (value) =>
    value === undefined || value === null ? true : isTimeFormatString(value),
};

const NUMBER_RULES = {
  required: testRequired,
  min: testMin,
  max: testMax,
};

const BOOLEAN_RULES = {
  required: testRequired,
};

const OBJECT_RULES = {
  required: testRequired,
  array: testArray,
  json: testJson,
};

/**
 * 회원정보 등록에서만 사용하는 Validator
 */
class Validator {
  #rules;

  constructor({ rules }) {
    this.#rules = rules || {};
  }

  validate(data = {}) {
    return this._validate(data, this.#rules);
  }
  _validate(data = {}, rules) {
    const results = {};
    for (const key of Object.getOwnPropertyNames(rules)) {
      const _rules = Array.isArray(rules[key]) ? rules[key] : [rules[key]];
      if (
        data[key] === undefined ||
        typeof data[key] === "string" ||
        typeof data[key] === "number" ||
        typeof data[key] === "boolean" ||
        typeof data[key] === "object"
      ) {
        results[key] = _rules
          .map(({ rule, type, valid, label, message }) => ({
            value: data[key],
            rule,
            type,
            message,
            valid,
            label,
            result:
              rule === "custom"
                ? data[key] === undefined || data[key] === null
                  ? true
                  : valid(data[key], data)
                : !!(type === "string"
                    ? STRING_RULES[rule] &&
                      STRING_RULES[rule](data[key], valid, data)
                    : type === "number"
                    ? NUMBER_RULES[rule] &&
                      NUMBER_RULES[rule](data[key], valid, data)
                    : type === "boolean"
                    ? BOOLEAN_RULES[rule] &&
                      BOOLEAN_RULES[rule](data[key], valid, data)
                    : type === "object"
                    ? OBJECT_RULES[rule] &&
                      OBJECT_RULES[rule](data[key], valid, data)
                    : false),
          }))
          .filter(({ result }) => !result);
      } else {
        console.error(key, data[key]);
        throw new Error();
      }
    }

    return results;
  }
}

export default Validator;
