<template>
  <div>
    <ejs-dialog
      ref="membershipFitResvePopup"
      header="회원권특전-골프 예약 특전"
      :allowDragging="true"
      :showCloseIcon="true"
      width="800"
      height="800"
      :isModal="true"
      @close="onMembershipFitResvePopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">사업장</div>
                    <ul class="content">
                      <li class="item">
                        <ejs-dropdownlist
                          cssClass="lookup-condition-dropdown"
                          :dataSource="
                            bizCodeDropdownListProperties.bizCodeList
                          "
                          :fields="bizCodeDropdownListProperties.bizCodeFields"
                          v-model="argBizCode"
                          @change="handleChangeBizCode($event)"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="membershipFitResveGrid"
                        :allowResizing="true"
                        :dataSource="membershipFitResveList"
                        :selectionSettings="selectionSettings"
                        :validationRules="GridValidationRules"
                        :allowExcelExport="true"
                        :columns="membershipFitResveGridColumn"
                        :provides="grid"
                        :isInPopup="true"
                        @headerCellInfo="membershipFitResveGridHeaderCellInfo"
                        @queryCellInfo="membershipFitResveGridQueryCellInfo"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                button-div="SAVE"
                :is-shortcut-button="true"
                @click.native="onSaveButtonClicked"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="closeMembershipFitResvePopup">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {
  Edit,
  Aggregate,
  ExcelExport,
  Filter,
  ForeignKey,
  Group,
  Resize,
} from "@syncfusion/ej2-vue-grids";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";

import {commonCodesGetColorValue, commonCodesGetComName} from "@/utils/commonCodes";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import { getCurrentBizCode, getBizCodesObject } from "@/utils/pageKeyUtil";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "MembershipFitResvePopup",
  components: {
    EjsGridWrapper,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  created: function () {},
  data: function () {
    return {
      grid: [Edit, Aggregate, Filter, Resize, Group, ExcelExport, ForeignKey],
      selectionSettings: {
        type: "Single",
        mode: "Both",
        enableToggle: false,
        persistSelection: false,
      },
      bizCodeDropdownListProperties: {
        bizCodeFields: { text: "bizName", value: "bizCode" },
        bizCodeList: getBizCodesObject(),
      },
      argBizCode: getCurrentBizCode(),
      membershipId: null,
      membershipFitResveList: [],
      membershipFitResveGridColumn: [
        {
          isPrimaryKey: true,
          field: "_rid",
          visible: false,
        },
        {
          allowEditing: false,
          field: "benItem",
          headerText: "특전항목",
          width: 120,
          comCode: "BEN_ITEM",
          valueAccessor: this.valueAccessor,
        },
        {
          allowEditing: false,
          field: "bsnCode",
          headerText: "영업구분",
          width: 90,
          visible: false,
        },
        {
          allowEditing: false,
          field: "bsnCodeName",
          headerText: "영업구분",
          width: 90,
        },
        {
          field: "benLmttDiv",
          headerText: "제한구분",
          width: 120,
          editType: "dropdownedit",
          isCommonCodeField: true,
          groupCode: "BEN_LMTT_DIV",
        },
        {
          allowEditing: true,
          isNumericType: true,
          inputNumberProperty: { maxLength: 3 }, // custom
          field: "lmttCnt",
          headerText: "제한횟수",
          required: true,
          textAlign: "center",
          type: "string",
          width: 90,
        },
      ],
      GridValidationRules: {
        benLmttDiv: {
          custom: {
            method: (args) => {
              const { changedRecords: gridChangedRecords } = this.$refs[
                "membershipFitResveGrid"
              ].getBatchChanges();
              const changedRecord = gridChangedRecords.find(
                (data) => args._rid === data._rid
              );
              if (changedRecord) {
                return changedRecord.benLmttDiv;
              } else {
                return true;
              }
            },
            message: "main.validationMessage.requiredMessage",
          },
        },
        lmttCnt: {
          custom: {
            method: (args) => {
              const { changedRecords: gridChangedRecords } = this.$refs[
                "membershipFitResveGrid"
              ].getBatchChanges();
              const changedRecord = gridChangedRecords.find(
                (data) => args._rid === data._rid
              );
              if (changedRecord) {
                return (
                  !(
                    changedRecord.lmttCnt === undefined ||
                    changedRecord.lmttCnt === null ||
                    `${changedRecord.lmttCnt}`.trim() === "" ||
                    isNaN(Number(changedRecord.lmttCnt))
                  ) && !(Number(changedRecord.lmttCnt) < 0)
                );
              } else {
                return true;
              }
            },
            message: "main.validationMessage.requiredMessage",
          },
        },
      },
    };
  },
  methods: {
    valueAccessor(field, data, column) {
      let code = data[field];
      let returnCode = null;
      if (code != null) {
        returnCode = commonCodesGetComName(column.comCode, code);
      }
      return returnCode;
    },
    async showMembershipFitResvePopup(popupData) {
      this.membershipId = popupData;

      await this.getTgMembershipFitResves(this.membershipId);
    },
    onMembershipFitResvePopupClosed() {
      this.$emit("popupClosed");
    },
    closeMembershipFitResvePopup: function () {
      this.$refs.membershipFitResvePopup.hide();
    },
    async getTgMembershipFitResves(membershipId) {
      this.membershipFitResveList = await GolfErpAPI.fetchTgMembershipFitResves(
        membershipId,
        this.argBizCode
      );
    },
    membershipFitResveGridHeaderCellInfo(args) {
      if (
        args.cell.column.field === "benLmttDiv" ||
        args.cell.column.field === "lmttCnt"
      ) {
        // 헤더에 필수영역 표시 클래스 추가
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    membershipFitResveGridQueryCellInfo(args) {
      const {
        column: {field},
        cell,
        data,
      } = args;
      if (
        field === "benLmttDiv" ||
        field === "lmttCnt"
      ) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (field === "bsnCodeName") {
        cell.style.color = commonCodesGetColorValue("BSN_CODE", data.bsnCode);
      }
      if (data.bsnCode === "%") {
        cell.style.fontWeight = "bold";
        if (field === "benItem") {
          args.rowSpan = 3;
        }
      }
    },
    async onSaveButtonClicked() {
      if (!this.$refs.membershipFitResveGrid.validate()) {
        return;
      }

      const {
        changedRecords,
      } = this.$refs.membershipFitResveGrid.getBatchChanges();

      let saveData = [];

      if (changedRecords.length > 0) {
        changedRecords.forEach((data) => {
          data.membershipId = this.membershipId;
          data.bizCode = this.argBizCode;

          saveData.push(data);
        });
      }

      if (!(saveData.length > 0)) {
        return;
      }

      await GolfErpAPI.patchTgMembershipFitResves(saveData);

      await this.getTgMembershipFitResves(this.membershipId);
    },
    async handleChangeBizCode({ e }) {
      if (!e) {
        return;
      }

      await this.getTgMembershipFitResves(this.membershipId);
    },
  },
};
</script>
