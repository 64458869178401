<template>
  <div>
    <ejs-dialog
      ref="membershipFitPaymtPopup"
      header="회원권특전-골프 요금 특전"
      :allowDragging="true"
      :showCloseIcon="true"
      width="1180"
      height="800"
      :isModal="true"
      @close="onMembershipFitPaymtPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">사업장</div>
                    <ul class="content">
                      <li class="item">
                        <ejs-dropdownlist
                          cssClass="lookup-condition-dropdown"
                          :dataSource="
                            bizCodeDropdownListProperties.bizCodeList
                          "
                          :fields="bizCodeDropdownListProperties.bizCodeFields"
                          v-model="argBizCode"
                          @change="handleChangeBizCode($event)"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="membershipFitPaymtGrid"
                        :allowResizing="true"
                        :dataSource="membershipFitPaymtList"
                        :selectionSettings="selectionSettings"
                        :validationRules="GridValidationRules"
                        :allowExcelExport="true"
                        :columns="membershipFitPaymtGridColumn"
                        :provides="grid"
                        :isInPopup="true"
                        @cellSaved="membershipFitPaymtGridCellSaved"
                        @headerCellInfo="membershipFitPaymtGridHeaderCellInfo"
                        @queryCellInfo="membershipFitPaymtGridQueryCellInfo"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                button-div="SAVE"
                :is-shortcut-button="true"
                @click.native="onSaveButtonClicked"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="closeMembershipFitPaymtPopup">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {
  Edit,
  Aggregate,
  ExcelExport,
  Filter,
  ForeignKey,
  Group,
  Resize,
} from "@syncfusion/ej2-vue-grids";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";

import { commonCodesGetCommonCodeByIdxAttrb } from "@/utils/commonCodes";
import { numberWithCommas } from "@/utils/number";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import { getCurrentBizCode, getBizCodesObject } from "@/utils/pageKeyUtil";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "MembershipFitPaymtPopup",
  components: {
    EjsGridWrapper,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  data: function () {
    return {
      grid: [Edit, Aggregate, Filter, Resize, Group, ExcelExport, ForeignKey],
      selectionSettings: {
        type: "Single",
        mode: "Both",
        enableToggle: false,
        persistSelection: false,
      },
      bizCodeDropdownListProperties: {
        bizCodeFields: { text: "bizName", value: "bizCode" },
        bizCodeList: getBizCodesObject(),
      },
      argBizCode: getCurrentBizCode(),
      membershipId: null,
      membershipFitPaymtList: [],
      membershipFitPaymtGridColumn: [
        {
          isPrimaryKey: true,
          field: "_rid",
          visible: false,
        },
        {
          allowEditing: false,
          field: "benTrget",
          headerText: "특전대상",
          width: 120,
          isCommonCodeField: true,
          groupCode: "BEN_TRGET",
        },
        {
          allowEditing: false,
          field: "bsnCode",
          headerText: "영업구분",
          width: 90,
          isCommonCodeField: true,
          groupCode: "BSN_CODE",
        },
        {
          field: "paymtCode",
          headerText: "요금코드",
          width: 120,
          editType: "dropdownedit",
          isCommonCodeField: true,
          groupCode: "PAYMT_CODE",
        },
        {
          field: "paymtCodeSelf",
          headerText: "요금코드(셀프)",
          width: 120,
          editType: "dropdownedit",
          isCommonCodeField: true,
          groupCode: "PAYMT_CODE",
        },
        {
          field: "cartDcMethod",
          headerText: "카트할인방법",
          width: 120,
          editType: "dropdownedit",
          isCommonCodeField: true,
          dataSource: commonCodesGetCommonCodeByIdxAttrb("DC_METHOD", 1, "Y"),
        },
        {
          allowEditing: true,
          isNumericType: true,
          field: "cartDcAmt",
          inputNumberProperty: { maxLength: 13 },
          headerText: "카트할인금액(율)",
          textAlign: "right",
          type: "string",
          width: 120,
        },
        {
          allowEditing: true,
          isNumericType: true,
          inputNumberProperty: { maxLength: 4, allowMinus: true }, // custom
          field: "webOpenTime",
          headerText: "웹오픈시간",
          required: true,
          textAlign: "center",
          type: "string",
          width: 90,
        },
      ],
      GridValidationRules: {
        paymtCode: {
          custom: {
            method: (args) => {
              const { changedRecords: gridChangedRecords } = this.$refs[
                "membershipFitPaymtGrid"
              ].getBatchChanges();
              const changedRecord = gridChangedRecords.find(
                (data) => args._rid === data._rid
              );
              if (changedRecord) {
                return changedRecord.paymtCode;
              } else {
                return true;
              }
            },
            message: "main.validationMessage.requiredMessage",
          },
        },
        paymtCodeSelf: {
          custom: {
            method: (args) => {
              const { changedRecords: gridChangedRecords } = this.$refs[
                "membershipFitPaymtGrid"
              ].getBatchChanges();
              const changedRecord = gridChangedRecords.find(
                (data) => args._rid === data._rid
              );
              if (changedRecord) {
                return changedRecord.paymtCodeSelf;
              } else {
                return true;
              }
            },
            message: "main.validationMessage.requiredMessage",
          },
        },
        cartDcAmt: {
          custom: {
            method: (args) => {
              const { cartDcMethod, cartDcAmt } = args;
              return !(
                cartDcMethod === "RATE" &&
                (cartDcAmt < 1 || cartDcAmt > 100)
              );
            },
            message: "main.validationMessage.rangeMessage",
            messageValue: [1, 100],
          },
        },
      },
    };
  },
  methods: {
    numberWithCommas,
    async showMembershipFitPaymtPopup(popupData) {
      this.membershipId = popupData;

      await this.getTgMembershipFitPaymts(this.membershipId);
    },
    onMembershipFitPaymtPopupClosed() {
      this.$emit("popupClosed");
    },
    closeMembershipFitPaymtPopup: function () {
      this.$refs.membershipFitPaymtPopup.hide();
    },
    async getTgMembershipFitPaymts(membershipId) {
      this.membershipFitPaymtList = await GolfErpAPI.fetchTgMembershipFitPaymts(
        membershipId,
        this.argBizCode
      );
    },
    membershipFitPaymtGridCellSaved(args) {
      const {
        rowData: { cartDcMethod, cartDcAmt },
        cell,
        columnName,
        value,
      } = args;
      if (columnName === "cartDcAmt") {
        cell.innerText = `${this.numberWithCommas(value)}${
          cartDcMethod === "RATE" ? "%" : ""
        }`;
      }
      if (columnName === "cartDcMethod") {
        cell.nextSibling.innerText = `${this.numberWithCommas(cartDcAmt)}${
          value === "RATE" ? "%" : ""
        }`;
      }
    },
    membershipFitPaymtGridHeaderCellInfo(args) {
      const isPaymtCode = args.cell.column.field === "paymtCode";
      const isPaymtCodeSelf = args.cell.column.field === "paymtCodeSelf";

      if (isPaymtCode || isPaymtCodeSelf) {
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    membershipFitPaymtGridQueryCellInfo(args) {
      const {
        data: { cartDcMethod, cartDcAmt },
        cell,
        column: { field },
      } = args;
      if (
          field === "paymtCode" ||
          field === "cartDcMethod" ||
          field === "cartDcAmt" ||
          field === "webOpenTime" ||
          field === "paymtCodeSelf"
      ) {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (field === "cartDcAmt") {
        cell.innerText = `${this.numberWithCommas(cartDcAmt)}${
          cartDcMethod === "RATE" ? "%" : ""
        }`;
      }
    },
    async onSaveButtonClicked() {
      if (!this.$refs.membershipFitPaymtGrid.validate()) {
        return;
      }

      const {
        changedRecords,
      } = this.$refs.membershipFitPaymtGrid.getBatchChanges();

      let saveData = [];

      if (changedRecords.length > 0) {
        changedRecords.forEach((data) => {
          data.membershipId = this.membershipId;
          data.bizCode = this.argBizCode;

          saveData.push(data);
        });
      }

      if (!(saveData.length > 0)) {
        return;
      }

      await GolfErpAPI.patchTgMembershipFitPaymts(saveData);
      await this.getTgMembershipFitPaymts(this.membershipId);
    },
    async handleChangeBizCode({ e }) {
      if (!e) {
        return;
      }

      await this.getTgMembershipFitPaymts(this.membershipId);
    },
  },
};
</script>
