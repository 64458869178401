<template>
  <div>
    <ejs-dialog
      allowDragging="true"
      header="상세검색"
      ref="dialog"
      showCloseIcon="true"
      width="387"
      v-bind="dialogProps"
      @close="onDialogClose"
      @overlayClick="onDialogOverlayClick"
    >
      <div class="window lookupDetail-memberInformationRegistration">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">
                    {{ labels.MemberNamePopup }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <input-text ref="searchMemberName" v-model="privateQ" />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.corporationDivision }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        ref="cprDivDropdown"
                        v-model="privateFilterOptions.corporationDivision"
                        :dataSource="commonCodesOfCorporationDivisions"
                        :allowFiltering="false"
                        :fields="{ text: 'comName', value: 'comCode' }"
                        cssClass="lookupDetail-condition-dropdown"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.memberDivision }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        ref="memberDivDropdown"
                        v-model="privateFilterOptions.memberDivision"
                        :dataSource="commonCodesOfMemberDivisions"
                        :allowFiltering="false"
                        :fields="{ text: 'comName', value: 'comCode' }"
                        cssClass="lookupDetail-condition-dropdown"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.memberGrade }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        ref="memberGradeDropdown"
                        v-model="privateFilterOptions.memberGrade"
                        :dataSource="commonCodesOfMemberGrades"
                        :allowFiltering="false"
                        :fields="{ text: 'comName', value: 'comCode' }"
                        cssClass="lookupDetail-condition-dropdown"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.webMemberId }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <input-text v-model="privateFilterOptions.webMemberId" />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.groupCompanyCode }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        ref="groupCompanyCodeDropdown"
                        v-model="privateFilterOptions.groupCompanyCode"
                        :dataSource="commonCodesOfGroupCompanyCodes"
                        :allowFiltering="false"
                        :fields="{ text: 'comName', value: 'comCode' }"
                        cssClass="lookupDetail-condition-dropdown"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.affltsCompanyCode }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                          ref="affltsCompanyCodeDropdown"
                          v-model="privateFilterOptions.affltsCompanyCode"
                          :dataSource="commonCodesOfAffltsCompanyCodes"
                          :allowFiltering="false"
                          :fields="{ text: 'comName', value: 'comCode' }"
                          cssClass="lookupDetail-condition-dropdown"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.smsReceiptDivision }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        ref="smsRecptnDivDropdown"
                        v-model="privateFilterOptions.smsReceiptDivision"
                        :dataSource="commonCodesOfSmsReceiptDivisions"
                        :allowFiltering="false"
                        :fields="{ text: 'comName', value: 'comCode' }"
                        cssClass="lookupDetail-condition-dropdown"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.membershipStatus }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        ref="membershipStatusDivDropdown"
                        v-model="privateFilterOptions.membershipStatus"
                        :dataSource="commonCodesOfMembershipStatuses"
                        :allowFiltering="false"
                        :fields="{ text: 'comName', value: 'comCode' }"
                        cssClass="lookupDetail-condition-dropdown"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.acquireDate }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ul class="check">
                        <li>
                          <label>
                            <input type="checkbox" v-model="useAcquireDate" />
                            <i></i>
                            <div class="label">사용</div>
                          </label>
                        </li>
                      </ul>
                    </li>
                    <li class="item dateRange">
                      <input-date-range
                        type="lookupDetail-condition"
                        :disabled="!useAcquireDate"
                        v-model="inputAcquireDateRange"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.nearbyCode }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                          ref="membershipStatusDivDropdown"
                          v-model="privateFilterOptions.nearbyCode"
                          :dataSource="commonCodesOfNearbyCodes"
                          :allowFiltering="false"
                          :fields="{ text: 'comName', value: 'comCode' }"
                          cssClass="lookupDetail-condition-dropdown"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.expireDate }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ul class="check">
                        <li>
                          <label>
                            <input type="checkbox" v-model="useExpireDate" />
                            <i></i>
                            <div class="label">사용</div>
                          </label>
                        </li>
                      </ul>
                    </li>
                    <li class="item dateRange">
                      <input-date-range
                        type="lookupDetail-condition"
                        :disabled="!useExpireDate"
                        v-model="inputExpireDateRange"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.birthday }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ul class="check">
                        <li>
                          <label>
                            <input type="checkbox" v-model="useBirthday" />
                            <i></i>
                            <div class="label">사용</div>
                          </label>
                        </li>
                      </ul>
                    </li>
                    <li class="item birthday">
                      <input-birthday
                        v-model="privateFilterOptions.birthday"
                        type="lookupDetail-condition"
                        :disabled="!useBirthday"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.gender }}
                  </div>
                  <ul class="content">
                    <li class="item check">
                      <ul class="check">
                        <li
                          :key="code.comCode"
                          v-for="code in commonCodesOfGenders"
                        >
                          <label>
                            <input
                              type="radio"
                              :key="`radio_${code.comCode}`"
                              :id="code.comCode"
                              v-model="privateFilterOptions.gender"
                              :value="code.comCode"
                            />
                            <i></i>
                            <div
                              class="label"
                              :key="`label_radio_${code.comCode}`"
                              :for="code.comCode"
                            >
                              {{ code.comName }}
                            </div>
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.isOut }}
                  </div>
                  <ul class="content">
                    <li class="item check">
                      <ul class="check">
                        <li>
                          <label>
                            <input
                              type="radio"
                              name="outFlag"
                              v-model="privateFilterOptions.isOut"
                              :value="undefined"
                            />
                            <i></i>
                            <div class="label">전체</div>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type="radio"
                              name="outFlag"
                              v-model="privateFilterOptions.isOut"
                              :value="false"
                            />
                            <i></i>
                            <div class="label">회원</div>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type="radio"
                              name="outFlag"
                              v-model="privateFilterOptions.isOut"
                              :value="true"
                            />
                            <i></i>
                            <div class="label">탈회회원</div>
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                  button-div="GET"
                  :is-shortcut-button="true"
                  v-on:click.native="onSubmitClick"
              >
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  button-div="DELETE"
                  :is-icon-custom="true"
                  @click.native="onResetClick">
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  :is-icon-custom="true"
                  @click.native="onCloseClick">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .window.lookupDetail-memberInformationRegistration .windowContent .content-wrapper .content-lookupDetail .lookupDetail-condition .field .content .item.expireDate {width: calc(100% - 40px);}
body .window.lookupDetail-memberInformationRegistration .windowContent .content-wrapper .content-lookupDetail .lookupDetail-condition .field .content .item.text {width: 40px;}
</style>

<script>
import moment from "moment";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import InputText from "@/components/common/text/InputText";
import InputBirthday from "@/components/common/datetime/InputBirthday";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import messagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton.vue";

const DEFAULT_FILTER_OPTIONS = {
  corporationDivision: "",
  memberDivision: "",
  memberGrade: "",
  endOfHpNumber: "",
  webMemberId: "",
  groupCompanyCode: "",
  affltsCompanyCode: "",
  smsReceiptDivision: "",
  membershipStatus: "",
  acquireDateFrom: "",
  acquireDateTo: "",
  expireDateFrom: "",
  expireDateTo: "",
  birthday: "",
  gender: "",
  isOut: false,
};

export default {
  name: "SearchMembersFilterOptionsPopup",
  components: {
    ErpButton,
    InputText,
    InputBirthday,
    InputDateRange,
  },
  props: {
    q: {
      type: String,
      default: () => "",
    },
    filterOptions: {
      type: Object,
      default: () => JSON.parse(JSON.stringify(DEFAULT_FILTER_OPTIONS)),
    },
    labels: {
      type: Object,
      default: null,
    },
  },
  mixins: [messagePopupDialogMixin],
  mounted() {
    if (
      this.filterOptions.acquireDateFrom ||
      this.filterOptions.acquireDateTo
    ) {
      this.useAcquireDate = true;
      this.inputAcquireDateRange = {
        from: this.filterOptions.acquireDateFrom,
        to: this.filterOptions.acquireDateTo,
      };
    }
    if (
      this.filterOptions.expireDateFrom ||
      this.filterOptions.expireDateTo
    ) {
      this.useExpireDate = true;
      this.inputExpireDateRange = {
        from: this.filterOptions.expireDateFrom,
        to: this.filterOptions.expireDateTo,
      };
    }

    if (this.filterOptions.birthday) {
      this.useBirthday = true;
    }

    this.privateFilterOptions.isOut = false;
  },
  data() {
    return {
      privateQ: this.q,
      privateFilterOptions: this.filterOptions,

      useAcquireDate: false,
      inputAcquireDateRange: {
        from: moment().add(-7, "day").format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
      },
      useExpireDate: false,
      inputExpireDateRange: {
        from: moment().add(-7, "day").format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
      },
      useBirthday: false,
    };
  },
  computed: {
    dialogProps() {
      return {
        animationSettings: { effect: "None" },
        isModal: false,
        position: { x: "center", y: "center" },
        visible: false,
      };
    },

    commonCodesOfCorporationDivisions() {
      const corporationDivisions = commonCodesGetCommonCode("CPR_DIV", true);

      if (corporationDivisions.findIndex((obj) => obj.comCode === "") === -1) {
        corporationDivisions.unshift({
          comCode: "",
          comName: "전체",
          codeAbrv: "",
          defaultFlag: true,
        });
      }

      return corporationDivisions;
    },
    commonCodesOfMemberDivisions() {
      const memberDivisions = commonCodesGetCommonCode(
        "MEMBER_DIV",
        true
      ).filter(({ comCode }) => !["NOM", "NOT"].includes(comCode));

      memberDivisions.unshift({
        comCode: "",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: true,
      });

      return memberDivisions;
    },
    commonCodesOfMemberGrades() {
      const memberGrades = commonCodesGetCommonCode("MEMBER_GRADE", true);

      memberGrades.unshift({
        comCode: "",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: true,
      });

      return memberGrades;
    },
    commonCodesOfGroupCompanyCodes() {
      const groupCompanyCodes = commonCodesGetCommonCode(
        "GROUP_COMPANY_CODE",
        true
      );

      groupCompanyCodes.unshift({
        comCode: "",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: true,
      });

      return groupCompanyCodes;
    },
    commonCodesOfAffltsCompanyCodes() {
      const affltsCompanyCode = commonCodesGetCommonCode(
          "AFFLTS_COMPANY_CODE",
          true);

      affltsCompanyCode.unshift({
        comCode: "",
        comName: "전체",
        sortNo: 0,
        codeAbrv: "",
        defaultFlag: true,
      });
      return affltsCompanyCode;
    },
    commonCodesOfSmsReceiptDivisions() {
      const smsReceiptDivisions = commonCodesGetCommonCode(
        "SMS_RECPTN_DIV",
        true
      );

      smsReceiptDivisions.unshift({
        comCode: "",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: true,
      });

      return smsReceiptDivisions;
    },
    commonCodesOfMembershipStatuses() {
      const membershipStatuses = commonCodesGetCommonCode(
        "MEMBERSHIP_STATUS",
        true
      );

      membershipStatuses.unshift({
        comCode: "",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: true,
      });

      return membershipStatuses;
    },
    commonCodesOfNearbyCodes() {
      const nearbyCodes = commonCodesGetCommonCode(
        "NEARBY_CODE",
        true
      );

      nearbyCodes.unshift({
        comCode: "",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: true,
      });

      return nearbyCodes;
    },
    commonCodesOfGenders() {
      const genders = commonCodesGetCommonCode("SEX_CODE", true);

      genders.unshift({
        comCode: "",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: true,
      });

      return genders;
    },
  },
  methods: {
    show() {
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    reset() {
      this.privateQ = "";
      this.privateFilterOptions = JSON.parse(
          JSON.stringify(DEFAULT_FILTER_OPTIONS)
      );
      this.useAcquireDate = false;
      this.inputAcquireDateRange = {
        from: moment().add(-7, "day").format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD"),
      };
      this.useExpireDate = false;
      this.inputExpireDateRange = {
        from: moment().add(-7, "day").format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD"),
      };
      this.useBirthday = false;
    },
    onDialogClose() {
      this.$emit("close");
    },
    onDialogOverlayClick() {
      this.hide();
    },
    onSubmitClick() {
      if (this.useAcquireDate) {
        this.privateFilterOptions.acquireDateFrom = this.inputAcquireDateRange.from;
        this.privateFilterOptions.acquireDateTo = this.inputAcquireDateRange.to;
      } else {
        this.privateFilterOptions.acquireDateFrom = null;
        this.privateFilterOptions.acquireDateTo = null;
      }

      if (this.useExpireDate) {
        this.privateFilterOptions.expireDateFrom = this.inputExpireDateRange.from;
        this.privateFilterOptions.expireDateTo = this.inputExpireDateRange.to;
      } else {
        this.privateFilterOptions.expireDateFrom = null;
        this.privateFilterOptions.expireDateTo = null;
      }

      if (!this.useBirthday) {
        this.privateFilterOptions.birthday = null;
      }
      this.$emit("submit", {
        q: this.privateQ,
        filterOptions: this.privateFilterOptions,
      });
    },
    onResetClick() {
      this.reset();
    },
    onCloseClick() {
      this.hide();
    },
  },
};
</script>

<style scoped></style>
